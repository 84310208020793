<template>
  <div class="container mx-auto my-2 flex items-center px-3">
    <SectionSeparatorLine
      v-if="titleAlignment && titleAlignment.name !== 'left'"
      data-testid="separator-left-line"
      :data="separatorLineData"
    />

    <p
      v-if="title?.length"
      data-testid="separator-title"
      :class="[
        'inline bg-white font-semibold uppercase',
        separator({
          titleAlignment: titleAlignment?.name
        })
      ]"
    >
      {{ title }}
    </p>

    <SectionSeparatorLine
      v-if="titleAlignment?.name !== 'right'"
      data-testid="separator-right-line"
      :data="separatorLineData"
    />
  </div>
</template>

<script lang="ts" setup>
import type { StrapiSectionsSeparator } from '@autobid/strapi-integration/typescript/strapi/sections/SectionsSeparator'
import type { StrapiComponentProps } from '@autobid/strapi-integration/typescript/strapi/Strapi'
import { separator } from '@autobid/ui/utils/cva/separatorCva'
import SectionSeparatorLine from './SeparatorLine.vue'

interface Props {
  data: StrapiComponentProps<StrapiSectionsSeparator>
}

const props = defineProps<Props>()

const { title, titleAlignment, ...separatorLineData } = props.data
</script>
